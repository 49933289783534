/* Mostly this stylesheet is just for psuedo elements that are more
   convenient to inlcude as styles than add with javascript */

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bbb;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #bbb;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: #bbb;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #bbb;
}
